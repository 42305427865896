import { Stack } from "@fluentui/react"
import React, { Dispatch } from "react";
import { actionCreators } from "../../../../../../core/actions/configurator-actions";
import { strings } from "../../../../../../localization/strings";
import { ParameterItem } from "../../../components/ParameterItem";
import { CheckProcessStageSectionOptionAccess } from "../../../helpers/evisibility";
import { ProcessStages, IPostOfferConfig, IAssessmentConfig } from "@piceasoft/core";

type TProps = {
    onChangeDispatch: Dispatch<any>
    config?: IPostOfferConfig
    channel: number
}

export const PostOfferStageAdditionalSettingsPivot: React.FC<TProps> = ({ onChangeDispatch, config, channel }) => {
    if (!config) return null

    return (
        <Stack style={{ padding: "0px 0px" }}>
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.POST_OFFER.ADDITIONAL_PIVOT.AUTO_SELECT_BEST_OFFER_TITLE}
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(channel, ProcessStages.PostOffer, 'Additional', 'Select best offer autommatically')}
                toggleChecked={config.autoSelectBestOffer}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.postOffer.onConfigCommit({
                        ...config,
                        autoSelectBestOffer: !config.autoSelectBestOffer ? true : undefined
                    }))
                }}
                description={strings.CONSTRUCTOR.STAGES.POST_OFFER.ADDITIONAL_PIVOT.AUTO_SELECT_BEST_OFFER_DESCRIPTION}
            />
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.HIDE_DEPENDENCY_TITLE}
                useToggle
                noSeparator
                toggleEnabled
                toggleChecked={config.hideDependency}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.postOffer.onConfigCommit({
                        ...config,
                        hideDependency: !config.hideDependency
                    }
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.HIDE_DEPENDENCY_DESCRIPTION}
            />
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.HIDE_SKIPED_TITLE}
                useToggle
                noSeparator
                toggleEnabled
                toggleChecked={config.hideSkiped}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.postOffer.onConfigCommit({
                        ...config,
                        hideSkiped: !config.hideSkiped
                    }
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.HIDE_SKIPED_DESCRIPTION}
            />
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.COLLAPSE_DONE_TITLE}
                useToggle
                toggleEnabled
                toggleChecked={config.collapseDone}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.postOffer.onConfigCommit({
                        ...config,
                        collapseDone: !config.collapseDone
                    }
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.COLLAPSE_DONE_DESCRIPTION}
            />
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.PRE_OFFER.ADDITIONAL_PIVOT.EXPAND_OFFER_DETAILS_TITLE}
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(channel, ProcessStages.PostOffer, 'Additional', 'Auto expand offer details')}
                toggleChecked={config.expandOfferDetails}
                toggleOnChange={ () => {
                    onChangeDispatch(actionCreators.postOffer.onConfigCommit({
                        ...config,
                        expandOfferDetails: !config.expandOfferDetails ? true : undefined
                    }))
                }}
                description={strings.CONSTRUCTOR.STAGES.PRE_OFFER.ADDITIONAL_PIVOT.EXPAND_OFFER_DETAILS_DESCRIPTION}
            />
        </Stack>
    )
}