import {
    DetailsList, DetailsListLayoutMode, DetailsRow, DirectionalHint,
    getTheme, IColumn, IconButton, IContextualMenuProps, IDetailsHeaderProps,
    IDetailsListProps, IDetailsRowStyles, IRenderFunction, MessageBar, MessageBarType,
    ScrollablePane, SelectionMode, SharedColors, Spinner, Stack, Sticky, Text, Image,
    ImageFit, IButtonStyles, PrimaryButton, Panel, PanelType, SpinnerSize, TooltipHost, ITooltipProps
} from "@fluentui/react"
import React from "react"
import { strings } from "../../../../localization/strings"
import { Section } from "../../../../components/shared/decorations/Section"
import { IPromotion } from "../../../../core/store/typings/IPromotion"
import { ItemsNotFound } from "../../../../components/shared/notFound/ItemsNotFound"
import { SomethingWentWrong } from "../../../../components/shared/somethingWentWrong/SomethingWentWrong"
import { IPromotionItem } from "../../../../core/store/typings/IPromotionItem"
import { portalApi } from "../../../../core/api/api"
import { onRenderColumnHeaderCommon } from "../../../../components/shared/detailsList/commonRenders"
import { SimpleCustomFilter } from "../../../../components/shared/filter/SimpleCustomFilter"
import unknown from "../../../../assets/unknown.png"
import { IResponseError } from "@piceasoft/core"
import { PromotionItemForm } from "../../../../components/shared/forms/PromotionItemForm"
import { CustomPanel } from "../../../../components/shared/panel/CustomPanel"
import { PromotionItemConditionsForm } from "../../../../components/shared/forms/PromotionItemConditionsForm"
import { IPromotionItemCondition } from "../../../../core/store/typings/IPromotionItemCondition"
import { useBoolean } from "@uifabric/react-hooks"
import { IKnownDevice } from "@piceasoft/core"
import { CSHHelpLink } from "../../../../components/shared/help/CSHHelp"

type TFinancePromotionItemsConfiguratorPivotProps = {
    data: IPromotion
}

export const FinancePromotionItemsConfiguratorPivot: React.FC<TFinancePromotionItemsConfiguratorPivotProps> = (props) => {

    const [itemsState, setItemsState] = React.useState<{ fetched: boolean, data?: IPromotionItem[], error?: string }>({ fetched: false });
    const [items, setItems] = React.useState<IPromotionItem[]>()
    const [searchString, setSearchString] = React.useState<string>()

    const [itemState, setItemState] = React.useState<{ id?: string, fetched: boolean, data?: IPromotionItem, errors?: IResponseError[] }>()
    const [devices, setDevices] = React.useState<IKnownDevice[]>()
    const [isConditionsShow, { setTrue: showConditions, setFalse: hideConditions }] = useBoolean(false)
    const [isItemShow, { setTrue: showItem, setFalse: hideItem }] = useBoolean(false)
    const [isFetching, { setTrue: startFetch, setFalse: stopFetch }] = useBoolean(false);
    const [isFetchingConditions, { setTrue: startFetchConditions, setFalse: stopFetchConditions }] = useBoolean(false);

    const onEditItem = async (itemId?: string) => {
        const devicesResult = await portalApi.organization.devices.getDevices()
        if (itemId) {
            const result = await portalApi.organization.promotionItems.getPromotionItem(props.data.id, itemId)
            if (result.successed) {
                setItemState({ ...itemState, data: result.data, fetched: true })
            }
            if (result.errors && result.errors.length > 0) {
                setItemState({ ...itemState, errors: result.errors, fetched: true })
            }
        } else {
            setItemState({ id: undefined, fetched: true })
        }
        setDevices(devicesResult.data ?? [])
    }

    const onDismissItem = () => {
        hideItem()
    }

    const onDismissConditions = () => {
        if (!isItemShow) {
            hideConditions()
        }
    }

    const onShowConditions = async (itemId?: string) => {
        onEditItem(itemId)
        showConditions()
    }

    const onShowItem = async (itemId?: string) => {
        onEditItem(itemId)
        showItem()
    }

    React.useEffect(() => {
        if (!isConditionsShow && !isItemShow) {
            setTimeout(() => {
                setItemState(undefined)
            }, 100)

        }
    }, [isConditionsShow, isItemShow])

    const onCommit = async (item: IPromotionItem) => {
        console.log(item)
        startFetch()
        if (props.data.id) {
            const result = item.id
                ? await portalApi.organization.promotionItems.updatePromotionItem(props.data.id, item)
                : await portalApi.organization.promotionItems.addPromotionItem(props.data.id, item)

            if (result.successed && result.data) {
                if (item.id) {
                    setItemsState({
                        ...itemsState, data: itemsState.data?.map(i => {
                            if (i.id === result.data?.id) {
                                return result.data as IPromotionItem
                            }
                            return i
                        })
                    })
                } else {
                    setItemsState({ ...itemsState, data: [...(itemsState.data ?? []), result.data] })
                }

                setItemState({ ...itemState, data: result.data, fetched: true, errors: result.errors })
                if (!item.id && result.data.id) {
                    showConditions()
                }
                hideItem()
            }
            if (result.errors && result.errors.length > 0) {
                setItemState({ id: item.id, errors: result.errors, fetched: true })
            }
            console.log(result)
            stopFetch()
        }
    }

    const onSubmitConditions = async (conditions: IPromotionItemCondition[]) => {
        console.log(conditions)
        startFetchConditions()
        if (itemState?.data) {
            console.log(props.data)
            const result = await portalApi.organization.promotionItems.updatePromotionItem(props.data.id, { ...itemState.data, conditions: conditions })
            console.log(result)
            if (result.successed && result.data) {
                if (itemState?.data?.id) {
                    setItemsState({
                        ...itemsState, data: itemsState.data?.map(i => {
                            if (i.id === itemState.id) {
                                return result.data as IPromotionItem
                            }
                            return i
                        })
                    })
                } else {
                    setItemsState({ ...itemsState, data: [...(itemsState.data ?? []), result.data] })
                }
                hideConditions()
                setItemState({ ...itemState, data: result.data, fetched: true, errors: result.errors })
            }
            if (result.errors && result.errors.length > 0) {
                setItemState({ ...itemState, fetched: true, errors: result.errors })
            }
        }
        stopFetchConditions()
    }

    const onDelete = async (itemId: string) => {
        if (props.data.id) {
            const result = await portalApi.organization.promotionItems.deletePromotionItem(props.data.id, itemId)
            if (result.successed) {
                setItemsState({ ...itemsState, fetched: true, data: itemsState.data?.filter(i => i.id !== itemId) })
            }
            if (result.errors && result.errors.length > 0) {
                setItemState({ id: itemId, fetched: true, errors: result.errors })
            }
        }
    }

    React.useEffect(() => {
        getItems()
    }, [])

    React.useEffect(() => {
        if (itemsState.data) {
            setItems(itemsState.data ? [...itemsState.data] : undefined)
        }
    }, [itemsState.data])

    React.useEffect(() => {
        setItems(() => {
            var newItems = itemsState.data ? [...itemsState.data] : []
            if (searchString) {
                newItems = [
                    ...((newItems as IPromotionItem[]).filter(i => {
                        let isFounded = false
                        const originValues = [
                            i.description,
                            i.device,
                            i.manufacturer,
                            i.name
                        ]
                        originValues.map(ov => {
                            if (ov && isFounded !== true) {
                                isFounded = ov.toString().toLowerCase().match(new RegExp(`\w*${searchString?.toLowerCase()}\w*`)) != null
                            }
                        })
                        return isFounded
                    }))
                ]
            }
            return newItems as IPromotionItem[]
        })
    }, [searchString])

    const getItems = async () => {
        if (props.data.id) {
            const result = await portalApi.organization.promotionItems.getPromotionItems(props.data.id)
            setItemsState({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: result.data })
        }
    }

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (hProps, defaultRender) => {
        if (!hProps) {
            return null;
        }

        return (
            <Sticky>
                <Stack>
                    <Stack.Item>
                        <Stack tokens={{ padding: "16px 32px 16px 20px", childrenGap: 12 }} horizontal>
                            <Stack horizontal tokens={{ childrenGap: 4 }}>
                                <Text variant="medium" style={{ fontWeight: 400, color: SharedColors.gray40 }}>{strings.COMMON.TOTAL}</Text>
                                <Stack horizontalAlign='center' verticalAlign='center' style={{ backgroundColor: theme.palette.neutralLight, borderRadius: 12 }}>
                                    <Text variant='small' style={{ fontWeight: 600, marginLeft: -1, padding: "2px 8px" }}>{itemsState.data?.length ?? 0}</Text>
                                </Stack>
                            </Stack>
                            {searchString && searchString.length > 0 && (
                                <Stack horizontal tokens={{ childrenGap: 4 }}>
                                    <Text variant="medium" style={{ fontWeight: 400, color: SharedColors.gray40 }}>{strings.COMMON.FILTERED}</Text>
                                    <Stack horizontalAlign='center' verticalAlign='center' style={{ backgroundColor: theme.palette.neutralLight, borderRadius: 12 }}>
                                        <Text variant='small' style={{ fontWeight: 600, marginLeft: -1, padding: "2px 8px" }}>{items?.length ?? 0}</Text>
                                    </Stack>
                                </Stack>
                            )}
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Sticky>
        );
    }

    const getItemMenuProps = (item: IPromotionItem, order: number): IContextualMenuProps => {
        return {
            items: [
                {
                    key: `edit-${item.id}`,
                    iconProps: { iconName: 'Edit', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => { item.id && onShowItem(item.id) },
                    text: strings.ORGANIZATION.PROMOTIONS.MENU.EDIT_ITEM,
                },
                {
                    key: `conditions-${item.id}`,
                    iconProps: { iconName: 'DependencyAdd', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => { item.id && onShowConditions(item.id) },
                    text: strings.ORGANIZATION.PROMOTIONS.MENU.ITEM_CONDITIONS,
                },
                {
                    key: `delete-${item.id}`,
                    iconProps: { iconName: 'Delete', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => { item.id && onDelete(item.id) },
                    text: strings.ORGANIZATION.PROMOTIONS.MENU.DELETE_ITEM,
                }
            ],
            directionalHint: DirectionalHint.bottomRightEdge
        }
    }

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: '',
            fieldName: 'icon',
            minWidth: 40,
            maxWidth: 40,
            isResizable: false,
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IPromotionItem) => (
                <Stack verticalFill verticalAlign="center">
                    <Image height={48} imageFit={ImageFit.contain} src={(item.imgSrc && item.imgSrc.length > 0) ? item.imgSrc : unknown} />
                </Stack>
            ),
            isPadded: true,
        },
        {
            key: 'column2',
            name: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.LIST_COLUMNS.NAME_DEVICE,
            fieldName: 'name_device',
            minWidth: 100,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IPromotionItem) => (
                <Stack verticalFill verticalAlign="center">
                    <Text variant="medium" style={{ color: theme.palette.black, fontWeight: 600 }}>{item.name}</Text>
                    <Text variant="small" style={{ color: SharedColors.gray40 }}>{item.manufacturer} {item.device}</Text>
                    <Text variant="small" style={{ color: SharedColors.gray20 }}>{item.description}</Text>
                </Stack>
            ),
            isPadded: true,
        },
        {
            key: 'column3',
            name: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.LIST_COLUMNS.FROM_TO,
            fieldName: 'from-to',
            minWidth: 330,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IPromotionItem) => {
                
                const options : Intl.DateTimeFormatOptions = {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    timeZoneName: "shortOffset"
                };
        
                return (
                    <Stack horizontal tokens={{ childrenGap: 8 }} verticalFill verticalAlign="center">
                        <Text variant="small" style={{ color: SharedColors.gray30 }}>
                            {new Date(item.from).toLocaleString(undefined, options)}
                        </Text>
                        <Text variant="small" style={{ color: SharedColors.gray20 }}>
                            &ndash;
                        </Text>
                        <Text variant="small" style={{ color: SharedColors.gray30 }}>
                            {new Date(item.to).toLocaleString(undefined, options)}
                        </Text>
                    </Stack>
                );
            },
            isPadded: true,
        },
        {
            key: 'column4',
            name: '',
            fieldName: 'menu',
            minWidth: 70,
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IPromotionItem, order?: number) => {
                return (
                    <Stack horizontal verticalFill horizontalAlign='end' verticalAlign='center' onClick={(ev) => ev.stopPropagation()}>
                        <Stack.Item>
                            <IconButton menuIconProps={{ iconName: "MoreVertical", className: 'menu-icon' }} styles={menuIconStyles} menuProps={order !== undefined ? getItemMenuProps(item, order) : undefined} />
                        </Stack.Item>
                    </Stack>
                )
            }
        }
    ]

    const onRenderRow: IDetailsListProps['onRenderRow'] = rProps => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (rProps) {
            customStyles.root = {
                cursor: 'pointer',
                ':hover': {
                    '.disabled-icon': {
                        color: theme.palette.black
                    },
                    '.menu-icon': {
                        color: theme.palette.black
                    },
                    '.up-icon': {
                        fontSize: 16,
                        color: theme.palette.black
                    }
                }
            };
            return <Stack onClick={() => { rProps.item && onShowConditions(rProps.item.id) }}><DetailsRow {...rProps} styles={customStyles} /></Stack>;
        }
        return null;
    }

    const onRenderPanelBody = () => {
        return (
            itemState?.fetched && devices && (
                <PromotionItemConditionsForm data={itemState?.data?.conditions ?? []} onDismiss={hideConditions} onSubmit={onSubmitConditions} devices={devices} isFetching={isFetchingConditions} />
            ) || (
                <Stack verticalFill verticalAlign="center" horizontalAlign="center">
                    <Spinner size={SpinnerSize.medium} label={strings.SPINNERS.DATA_IS_GETTING} />
                </Stack>
            )
        )
    }

    const onRenderHeader = () => {
        return (
            <Stack grow horizontal tokens={{ padding: '0px 16px', childrenGap: 16 }}>
                {itemState?.data && (
                    <Stack grow tokens={{ childrenGap: 16 }}>
                        
                        <Stack grow horizontal>
                            <Stack grow horizontal>
                                <Text variant="xLarge">{strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.ITEM_CONDITIONS}</Text>
                            </Stack> 
                            <Stack horizontal style={{ alignItems: "center" }}>
                                    <CSHHelpLink articleid='1157' />
                            </Stack>
                        </Stack>
                        
                        
                        
                        <Stack horizontal tokens={{ childrenGap: 16 }}>
                            <Stack verticalFill verticalAlign="center">
                                <Image height={72} width={72} imageFit={ImageFit.contain} src={(itemState.data.imgSrc && itemState.data.imgSrc.length > 0) ? itemState.data.imgSrc : unknown} />
                            </Stack>
                            <Stack verticalFill verticalAlign="center">
                                <Text variant="mediumPlus" style={{ color: theme.palette.black, fontWeight: 600 }}>{itemState.data.name} </Text>
                                <Text variant="medium" style={{ color: theme.palette.black, fontWeight: 400 }}>{itemState.data.manufacturer} {itemState.data.device}</Text>
                                <Text variant="small" style={{ color: SharedColors.gray30 }}>{itemState.data.description}</Text>
                            </Stack>
                            <Stack.Item>
                                <TooltipHost content={strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.EDIT_ITEM} tooltipProps={tooltipProps}>
                                    <IconButton menuIconProps={{ iconName: "Edit" }} onClick={showItem} />
                                </TooltipHost>
                            </Stack.Item>
                        </Stack>
                    </Stack>
                )}
            </Stack>
        )
    }

    return (
        <Stack verticalFill>
            <Stack.Item verticalFill>
                {!itemsState.error && itemsState.fetched && (itemsState.data && itemsState.data.length > 0 && (
                    <Stack verticalFill>
                        <Stack.Item>
                            <Stack horizontal grow tokens={{ padding: "24px 24px 16px 24px" }}>
                                <Stack.Item grow>
                                    <PrimaryButton onClick={() => onShowItem()}>
                                        {strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.NOT_FOUND_CASE.ADD_BUTTON}
                                    </PrimaryButton>
                                </Stack.Item>
                                <SimpleCustomFilter onChange={(ev, value) => setSearchString(value)} />
                            </Stack>
                        </Stack.Item>
                        <Section verticalFill flat max tokens={{ padding: "0px" }} grow>
                            <Stack verticalFill tokens={{ childrenGap: 16 }}>
                                {items && (
                                    <Stack.Item verticalFill style={{ position: 'relative' }}>
                                        <ScrollablePane>
                                            <DetailsList
                                                styles={{ root: { display: "flex" }, contentWrapper: { display: "flex" } }}
                                                items={items ?? itemsState.data}
                                                columns={columns}
                                                getKey={(item: IPromotionItem) => item.id as string}
                                                selectionMode={SelectionMode.none}
                                                layoutMode={DetailsListLayoutMode.justified}
                                                onRenderDetailsHeader={onRenderDetailsHeader}
                                                isHeaderVisible={true}
                                                onRenderRow={onRenderRow}
                                            />
                                        </ScrollablePane>
                                    </Stack.Item>
                                ) || (
                                        <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                                            <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                                        </Stack>
                                    )}
                            </Stack>
                        </Section>
                    </Stack>
                ) || (
                        <ItemsNotFound
                            info={strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.NOT_FOUND_CASE.INFO}
                            onClick={() => onShowItem()}
                            buttonText={strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.NOT_FOUND_CASE.ADD_BUTTON}
                            suggestion={strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.NOT_FOUND_CASE.SUGGESTION}
                            imgSrc={"images/navigation/images/startup.png"}
                        />
                    )
                ) || (
                        <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                            <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                        </Stack>
                    )}
                {itemsState.error && (
                    <Stack verticalFill>
                        {itemsState.error.length > 0 && (
                            <MessageBar messageBarType={MessageBarType.error}>{itemsState.error}</MessageBar>
                        )}
                        <SomethingWentWrong action={getItems} />
                    </Stack>
                )}
            </Stack.Item>
            <CustomPanel
                isOpen={isItemShow}
                onCancel={onDismissItem}
                noCancelOnDissmiss={true}
                title={itemState?.data?.id ?
                    strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.EDIT_ITEM :
                    strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.NEW_ITEM
                }
            >
                {itemState && devices && (
                    <PromotionItemForm
                        itemState={itemState}
                        onDismiss={onDismissItem}
                        onDelete={onDelete}
                        onCommit={onCommit}
                        promotionId={props.data.id}
                        devices={devices}
                        isFetching={isFetching}
                    />
                ) || (
                        <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                            <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                        </Stack>
                    )}
            </CustomPanel>
            <Panel type={PanelType.custom}
                customWidth={'1280px'}
                closeButtonAriaLabel={strings.BUTTONS.TEXT.CLOSE}
                isOpen={isConditionsShow}
                onDismiss={onDismissConditions}
                styles={{ scrollableContent: { display: 'flex', height: '100%', flexGrow: 1 } }}
                isFooterAtBottom={true}
                onRenderHeader={onRenderHeader}
                onRenderBody={onRenderPanelBody}
            />
        </Stack >
    )
}

const theme = getTheme();

const menuIconStyles: IButtonStyles = {
    menuIcon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16
    },
    icon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16,
        padding: 0
    },
    menuIconExpanded: {
        color: theme.palette.black
    }
}

const tooltipProps: Partial<ITooltipProps> = {
    calloutProps: {
        styles: {
            beak: { background: theme.palette.black },
            beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
            calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
        }
    }
}
