import { getTheme, IconButton, MessageBar, MessageBarType, SharedColors, Stack, Text, IContextualMenuProps, CommandBarButton } from "@fluentui/react"
import { Dispatch } from "react";
import { useSelector } from "react-redux";
import { ICommonOfferConfig, Inspections, ProcessStages, SoftwareModes, IPostOfferConfig, IConfigErrors, DataCollectionModes, InterviewModes } from "@piceasoft/core";
import { strings } from "../../../../localization/strings";
import { StageBox } from "../components/StageBox";
import { IStore } from "../../../../core/store";
import { getInspectionIcon } from "./Assessment";
import { getLocalizedInspectionName, getLocalizedInterviewModeName, getLocalizedSoftwareModeName } from "../../../../localization/helpers/transaction";
import { CheckAssesmentModuleAccess, Experience } from "../helpers/evisibility";
import { actionCreators } from "../../../../core/actions/configurator-actions";
import { InspectionItem } from "./common/InspectionItem";
import { DeviceCheckSolutions } from "../../../../core/store/typings/DeviceCheckSolutions";

const multipleSubitemsAllowed = [InterviewModes.Sequence.toString(), InterviewModes.List.toString()]

type TProps = {
    config: IPostOfferConfig
    commonConfig: ICommonOfferConfig
    channel: Experience
    deviceCheckSolution: DeviceCheckSolutions
    onChangeDispatch: Dispatch<any>
    showSettings: () => void
    showCustomSettings: (offerProviderCode?: string) => void
    showDiscountsSettings: () => void
    setInspectionParams: (index: number, pivot: string) => void
}

export const PostOffer: React.FC<TProps> = (props) => {

    const useGradesCategories = useSelector((s: IStore) => s.configurator.useGradesCategories)
    const gradesCategories = useSelector((s: IStore) => s.configurator.gradesCategories)

    let newInspectionMenuProps: IContextualMenuProps = {
        items: [
            {
                inspection: Inspections.Software,
                key: props.deviceCheckSolution == DeviceCheckSolutions.PiceaOne ? SoftwareModes.Piceasoft.toString() : SoftwareModes.PiceaMobile.toString(),
                iconProps: { iconName: getInspectionIcon(Inspections.Software), style: { color: theme.palette.black, fontSize: 14 } },
                text: getLocalizedInspectionName(Inspections.Software)  + ' ' + (props.deviceCheckSolution == DeviceCheckSolutions.PiceaOne ? getLocalizedSoftwareModeName(SoftwareModes.Piceasoft) : getLocalizedSoftwareModeName(SoftwareModes.PiceaMobile)) ,
                onClick: () => { 
                    if(props.deviceCheckSolution == DeviceCheckSolutions.PiceaOne){
                        props.onChangeDispatch(actionCreators.inspections.software.addSoftware(ProcessStages.PostOffer, SoftwareModes.Piceasoft))
                    }
                    else{
                        props.onChangeDispatch(actionCreators.inspections.software.addSoftware(ProcessStages.PostOffer, SoftwareModes.PiceaMobile))
                    }
                }
            },
            {
                inspection: Inspections.DataCollection,
                key: Inspections.DataCollection.toString(),
                iconProps: { iconName: getInspectionIcon(Inspections.DataCollection), style: { color: theme.palette.black, fontSize: 14 } },
                text: getLocalizedInspectionName(Inspections.DataCollection),
                onClick: () => { 
                    props.onChangeDispatch(actionCreators.inspections.dataCollection.addDataCollection( ProcessStages.PostOffer, DataCollectionModes.Standard))
                }
            },
            {
                inspection: Inspections.Interview,
                key: Inspections.Interview.toString(),
                iconProps: { iconName: getInspectionIcon(Inspections.Interview), style: { color: theme.palette.black, fontSize: 14 } },
                text: getLocalizedInspectionName(Inspections.Interview),
                subMenuProps: {
                    items: [
                        {
                            key: InterviewModes.Sequence.toString(),
                            onClick: () => { props.onChangeDispatch(actionCreators.inspections.interview.addInterview(ProcessStages.PostOffer, InterviewModes.Sequence)) },
                            text: getLocalizedInterviewModeName(InterviewModes.Sequence)
                        },
                        {
                            key: InterviewModes.List.toString(),
                            onClick: () => { props.onChangeDispatch(actionCreators.inspections.interview.addInterview(ProcessStages.PostOffer, InterviewModes.List)) },
                            text: getLocalizedInterviewModeName(InterviewModes.List)
                        },
                    ]
                }
            }
        ]
    }

    let availableItems = newInspectionMenuProps.items.length;
    newInspectionMenuProps.items = newInspectionMenuProps.items.map(function (value) {

        if( value.inspection === Inspections.Software) {
            if( !CheckAssesmentModuleAccess( props.channel, value.inspection, value.key)) {
                value.title = strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.DEVICE_CHECK_SOLUTION_FEATURE_IS_NOT_SUPPORTED;
                value.disabled = true;
                availableItems -= 1;
                if (value.iconProps && value.iconProps.style) {
                    value.iconProps.style.color = 'rgb(174 172 170)';
                }   
            }
        }
        
        if (value.subMenuProps !== undefined && value.subMenuProps.items) {
            value.subMenuProps.items = value.subMenuProps.items.map((item) => {
                let disableTitle;

                if ( !multipleSubitemsAllowed.includes(item.key) && props.config.modules && props.config.modules.find(m => m.type.toString() == value.key && (m.config as any)?.mode.toString() == item.key)) {
                    disableTitle = strings.CONSTRUCTOR.INSPECTIONS.COMMON.MENU_ITEM_IS_ALREADY_SELECTED;
                }
        
                if (!CheckAssesmentModuleAccess(props.channel, value.inspection, item.key)) {
                    if (disableTitle) {
                        disableTitle += '\n';
                    }
                    disableTitle += strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.SERVICE_CHANNEL_FEATURE_IS_NOT_SUPPORTED;
                }

                if (disableTitle) {
                    item.disabled = true;
                    item.title = disableTitle;
                    if (item.iconProps && item.iconProps.style ){
                        item.iconProps.style.color = 'rgb(174 172 170)';
                    }
                }
                return item;
            });
        }

        let disableTitle;

        if (value.subMenuProps && !value.subMenuProps.items.find(i => !i.disabled)) {
            disableTitle = strings.CONSTRUCTOR.INSPECTIONS.COMMON.ALL_SUBMENU_ITEMS_ARE_ALREADY_SELECTED;
        }
        
        if (!value.subMenuProps && Inspections.DataCollection !== value.inspection && props.config.modules && props.config.modules.find(m => m.type.toString() === value.inspection)) {
            disableTitle = strings.CONSTRUCTOR.INSPECTIONS.COMMON.MENU_ITEM_IS_ALREADY_SELECTED;
        }

        // We can select only one item from Photos - Free or Collection.
        if (value.key == Inspections.Photographic.toString() && props.config.modules && props.config.modules.find(m => m.type.toString() == Inspections.Photographic.toString())) {
            disableTitle = strings.CONSTRUCTOR.INSPECTIONS.COMMON.MENU_ITEM_IS_ALREADY_SELECTED;
        }

        if ((value.inspection === Inspections.Erase || value.inspection === Inspections.DataCollection ) && props.channel != Experience.Retail) {
            if (disableTitle) {
                disableTitle += '\n';
            }

            disableTitle += strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.SERVICE_CHANNEL_FEATURE_IS_NOT_SUPPORTED;
        }

        if (disableTitle) {
            value.disabled = true;
            value.title = disableTitle;
            if (value.iconProps && value.iconProps.style) {
                value.iconProps.style.color = 'rgb(174 172 170)';
            }
        }
        return value;
    });
    newInspectionMenuProps.styles = { subComponentStyles: { menuItem: { color:theme.palette.green, cursor:'help'}}};

    const commands = () => (
        <>
            { 0 < availableItems && <CommandBarButton iconProps={{ iconName: "Add" }} menuProps={newInspectionMenuProps} text={strings.BUTTONS.TEXT.ADD} disabled={false} />}
        </>
    )

    return (
        <StageBox title={strings.CONSTRUCTOR.STAGES.POST_OFFER.TITLE} onCommandRender={commands}  settingsButtonProps={{ onClick: props.showSettings }}>
            <Stack tokens={{ childrenGap: 16 }}>
                {props.commonConfig.allowDiscount && (
                    <OfferItem title={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.DISCOUNTS_PIVOT.TITLE}
                        onConfig={props.showDiscountsSettings}
                        config={props.commonConfig}
                    />
                )}
                {props.commonConfig.providers?.map((co, index) => (
                    <OfferItem key={index} title={co.name}
                        onConfig={() => props.showCustomSettings(co.code)} description={co.description}
                        errors={co.errors}
                        config={props.commonConfig}
                    />
                ))}
                {props.config.modules?.sort((a, b) => a.index - b.index).map((i, localIndex) => {
                    if (i) {
                        return (
                            <InspectionItem
                                key={`${localIndex}-${i.index}-${i.type}`}
                                stage={ProcessStages.PostOffer}
                                onChangeDispatch={props.onChangeDispatch}
                                inspection={i}
                                icon={i.config.ui?.icon ?? getInspectionIcon(i.type)}
                                setInspectionParams={props.setInspectionParams}
                                useGradeCategories={useGradesCategories}
                                gradesCategories={gradesCategories}
                                isExperimental={[Inspections.Erase].includes(i.type)}
                                withoutGrades={[Inspections.DataCollection, Inspections.Erase].includes(i.type)}
                            />
                        )
                    }
                })}
            </Stack>
        </StageBox>
    )
}

type TOfferItemProps = {
    title: string
    description?: string
    config?: ICommonOfferConfig
    errors?: IConfigErrors[]
    onConfig: () => void
}

export const OfferItem: React.FC<TOfferItemProps> = (props) => {
    const errors = props.errors ?? [] 
    let errorListRender: JSX.Element = (
        <div style={{ display: "inline" }}>
            <ul style={{ paddingLeft: 16 }}>
                {errors?.map((error, index) => (
                    <li key={index}>{error.text}</li>
                ))}
            </ul>
        </div>
    )
    const renderErrors = (
        <>
            {errors && errors.length > 0 && (
                <MessageBar isMultiline={true} overflowButtonAriaLabel="See more" messageBarType={MessageBarType.severeWarning}>
                    <b style={{ width: "100%" }}>{strings.CONSTRUCTOR.INSPECTIONS.COMMON.NOTES_ON_SETTINGS_MODULE}</b> {errorListRender}
                </MessageBar>
            )}
        </>
    )
    return (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Stack.Item grow styles={{ root: { paddingRight: 18 } }}>
                <Stack styles={{
                    root: {
                        color: theme.palette.black,
                        boxShadow: theme.effects.elevation16,
                        position: "relative",
                    }
                }}>
                    <Stack onClick={props.onConfig} styles={{
                        root: {
                            padding: 16,
                            color: theme.palette.black,
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderColor: errors && errors.length ? '#ee7676' : theme.palette.neutralQuaternaryAlt,
                            backgroundColor: theme.palette.white,
                            position: "relative",
                            ":hover": {
                                cursor: "pointer",
                                borderColor: errors && errors.length ? SharedColors.redOrange10 : theme.palette.themePrimary,
                                backgroundColor: errors && errors.length ? theme.palette.neutralLighter : theme.palette.themeLighterAlt
                            }
                        }
                    }}>
                        <Stack.Item>
                            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                <Text styles={{ root: { color: theme.palette.black, fontWeight: 600 } }}>{props.title}</Text>
                            </Stack>
                        </Stack.Item>
                        {props.description && (
                            <Stack.Item>
                                <Text variant="small" styles={{ root: { color: theme.palette.themePrimary } }}>{props.description}</Text>
                            </Stack.Item>
                        )}
                    </Stack>
                    <Stack verticalFill verticalAlign="center" styles={{ root: { position: "absolute", top: 0, right: -18, bottom: 0, zIndex: 100 } }}>
                        <IconButton onClick={props.onConfig} iconProps={{ iconName: "Repair" }} styles={{
                            root: {
                                width: 36,
                                height: 36,
                                backgroundColor: theme.palette.white,
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: errors && errors.length ? '#ee7676' : theme.palette.neutralQuaternaryAlt,
                                borderRadius: "50%",
                                color: errors && errors.length ? SharedColors.redOrange10 : theme.palette.black,
                                boxShadow: theme.effects.elevation8,
                                ":hover": {
                                    borderColor: errors && errors.length ? SharedColors.redOrange10 : theme.palette.themePrimary,
                                    backgroundColor: errors && errors.length ? theme.palette.neutralLighter : theme.palette.themeLighterAlt
                                }
                            },
                            flexContainer: {
                                borderRadius: "50%",
                                ":hover": {
                                    borderColor: theme.palette.themePrimary,
                                    backgroundColor: theme.palette.themeLighterAlt
                                }
                            },
                            iconHovered: {
                                color: errors && errors.length ? SharedColors.redOrange10 : theme.palette.black
                            }
                        }} />
                    </Stack>
                    <Stack.Item>
                        {renderErrors}
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();
