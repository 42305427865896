import {
    CommandBarButton, getTheme, IContextualMenuProps,
    MessageBar, MessageBarType, Stack
} from "@fluentui/react"
import { Dispatch } from "react";
import { useSelector } from "react-redux";
import { actionCreators } from "../../../../core/actions/configurator-actions";
import { IStore } from "../../../../core/store";
import { 
    SoftwareModes, Inspections, ProcessStages, ManualGradingModes,
    PhotographicModes, IAssessmentConfig,  InterviewModes, DiagnosticsModes,
    AIGradingModes, DataCollectionModes, EraseModes
} from '@piceasoft/core';
import {
   getLocalizedDiagnosticsModeName, getLocalizedInspectionName, getLocalizedInterviewModeName, getLocalizedPhotographicModeName, getLocalizedSoftwareModeName
} from "../../../../localization/helpers/transaction";
import { strings } from "../../../../localization/strings";
import { StageBox } from "../components/StageBox";
import { InspectionItem } from "./common/InspectionItem";
import { CheckAssesmentModuleAccess, Experience } from "../helpers/evisibility";
import { DeviceCheckSolutions } from "../../../../core/store/typings/DeviceCheckSolutions";

type TProps = {
    config: IAssessmentConfig
    setInspectionParams: (index: number, pivot: string) => void
    onChangeDispatch: Dispatch<any>
    showSettings: () => void
    channel: Experience,
    deviceCheckSolution: DeviceCheckSolutions
}

export const getInspectionIcon = (method: Inspections, stage?: ProcessStages): string | undefined => {
    switch (method) {
        case Inspections.Interview: return "RedEye"
        case Inspections.Photographic: return "Camera"
        case Inspections.Diagnostics: return "Medical"
        case Inspections.Software: return (stage && stage === ProcessStages.PostOffer) ? "Encryption" : "BlockedSite"
        case Inspections.DataCollection: return "PageEdit"
        case Inspections.Erase: return "EraseTool"
        case Inspections.AIGrading: return "Robot"
        case Inspections.ManualGrading: return "AssessmentGroup"
    }
}

export const Assessment: React.FC<TProps> = (props) => {

    const useGradesCategories = useSelector((s: IStore) => s.configurator.useGradesCategories)
    const gradesCategories = useSelector((s: IStore) => s.configurator.gradesCategories)

    let newInspectionMenuProps: IContextualMenuProps = {
        items: [
            {
                inspection: Inspections.Interview,
                key: Inspections.Interview.toString(),
                iconProps: { iconName: getInspectionIcon(Inspections.Interview), style: { color: theme.palette.black, fontSize: 14 } },
                text: getLocalizedInspectionName(Inspections.Interview),
                subMenuProps: {
                    items: [
                        {
                            key: InterviewModes.Sequence.toString(),
                            onClick: () => { props.onChangeDispatch(actionCreators.inspections.interview.addInterview(ProcessStages.Assessment, InterviewModes.Sequence)) },
                            text: getLocalizedInterviewModeName(InterviewModes.Sequence)
                        },
                        {
                            key: InterviewModes.List.toString(),
                            onClick: () => { props.onChangeDispatch(actionCreators.inspections.interview.addInterview(ProcessStages.Assessment, InterviewModes.List)) },
                            text: getLocalizedInterviewModeName(InterviewModes.List)
                        },
                        // {
                        //     key: InterviewModes.Manual.toString(),
                        //     disabled: true,
                        //     // onClick: () => { props.onChangeDispatch(actionCreators.inspections.addInterview(ProcessStages.Assessment, InterviewModes.Manual)) },
                        //     text: getLocalizedInterviewModeName(InterviewModes.Manual)
                        // }
                    ]
                }
            },
            {
                inspection: Inspections.Photographic,
                key: Inspections.Photographic.toString(),
                iconProps: { iconName: getInspectionIcon(Inspections.Photographic), style: { color: theme.palette.black, fontSize: 14 } },
                text: getLocalizedInspectionName(Inspections.Photographic),
                subMenuProps: {
                    items: [
                        {
                            key: PhotographicModes.Collection.toString(),
                            onClick: () => { props.onChangeDispatch(actionCreators.inspections.photographic.addPhotographic(ProcessStages.Assessment, PhotographicModes.Collection)) },
                            text: getLocalizedPhotographicModeName(PhotographicModes.Collection),
                        },
                        {
                            key: PhotographicModes.Free.toString(),
                            onClick: () => { props.onChangeDispatch(actionCreators.inspections.photographic.addPhotographic(ProcessStages.Assessment, PhotographicModes.Free)) },
                            text: getLocalizedPhotographicModeName(PhotographicModes.Free),
                        }
                    ]
                }
            },
            {
                inspection: Inspections.Diagnostics,
                key: Inspections.Diagnostics.toString(),
                iconProps: { iconName: getInspectionIcon(Inspections.Diagnostics), style: { color: theme.palette.black, fontSize: 14 } },
                onClick: () => { 
                    if(props.deviceCheckSolution == DeviceCheckSolutions.PiceaOne){
                        props.onChangeDispatch(actionCreators.inspections.diagnostics.addDiagnostics(ProcessStages.Assessment, DiagnosticsModes.Piceasoft))
                    }
                    else if( props.deviceCheckSolution == DeviceCheckSolutions.PiceaMobile){
                        props.onChangeDispatch(actionCreators.inspections.diagnostics.addDiagnostics(ProcessStages.Assessment, DiagnosticsModes.PiceaMobile))
                    }
                    else {
                        props.onChangeDispatch(actionCreators.inspections.diagnostics.addDiagnostics(ProcessStages.Assessment, DiagnosticsModes.WebBased))
                    }
           
                 },
                text: DeviceCheckSolutions.WebBased === props.deviceCheckSolution ? getLocalizedInspectionName(Inspections.Diagnostics)
                : getLocalizedInspectionName(Inspections.Diagnostics)
                 + ' ' + (props.deviceCheckSolution == DeviceCheckSolutions.PiceaOne ? getLocalizedDiagnosticsModeName(DiagnosticsModes.Piceasoft) : getLocalizedDiagnosticsModeName(DiagnosticsModes.PiceaMobile))
            },
            {
                inspection: Inspections.Software,
                key: props.deviceCheckSolution == DeviceCheckSolutions.PiceaOne ? SoftwareModes.Piceasoft.toString() : ( props.deviceCheckSolution == DeviceCheckSolutions.PiceaMobile ?  SoftwareModes.PiceaMobile.toString() : "") ,
                iconProps: { iconName: getInspectionIcon(Inspections.Software), style: { color: theme.palette.black, fontSize: 14 } },
                text: getLocalizedInspectionName(Inspections.Software)  
                    + ' ' + (props.deviceCheckSolution == DeviceCheckSolutions.PiceaOne ? getLocalizedSoftwareModeName(SoftwareModes.Piceasoft) : 
                    (props.deviceCheckSolution == DeviceCheckSolutions.PiceaMobile ? getLocalizedSoftwareModeName(SoftwareModes.PiceaMobile) : "")) ,
                onClick: () => { 
                    if(props.deviceCheckSolution == DeviceCheckSolutions.PiceaOne){
                        props.onChangeDispatch(actionCreators.inspections.software.addSoftware(ProcessStages.Assessment, SoftwareModes.Piceasoft))
                    }
                    else{
                        props.onChangeDispatch(actionCreators.inspections.software.addSoftware(ProcessStages.Assessment, SoftwareModes.PiceaMobile))
                    }
                }
            },
            {
                inspection: Inspections.DataCollection,
                key: Inspections.DataCollection.toString(),
                iconProps: { iconName: getInspectionIcon(Inspections.DataCollection), style: { color: theme.palette.black, fontSize: 14 } },
                text: getLocalizedInspectionName(Inspections.DataCollection),
                onClick: () => { props.onChangeDispatch(actionCreators.inspections.dataCollection.addDataCollection(ProcessStages.Assessment, DataCollectionModes.Standard)) },
                // subMenuProps: {
                //     items: [
                //         {
                //             key: DataCollectionModes.Standard.toString(),
                //             onClick: () => { props.onChangeDispatch(actionCreators.inspections.dataCollection.addDataCollection(ProcessStages.Assessment, DataCollectionModes.Standard)) },
                //             text: getLocalizedDataCollectionModeName(DataCollectionModes.Standard)
                //         },
                //     ]
                // }
            },
            {
                inspection: Inspections.Erase,
                key: Inspections.Erase.toString(),
                iconProps: { iconName: getInspectionIcon(Inspections.Erase), style: { color: theme.palette.black, fontSize: 14 } },
                text: getLocalizedInspectionName(Inspections.Erase),
                onClick: () => { props.onChangeDispatch(actionCreators.inspections.erase.addErase(ProcessStages.Assessment, EraseModes.Default)) }
            },
            {
                inspection: Inspections.AIGrading,
                key: Inspections.AIGrading.toString(),
                iconProps: { iconName: getInspectionIcon(Inspections.AIGrading), style: { color: theme.palette.black, fontSize: 14 } },
                text: getLocalizedInspectionName(Inspections.AIGrading),
                onClick: () => { props.onChangeDispatch(actionCreators.inspections.aigrading.addAigrading(ProcessStages.Assessment, AIGradingModes.Default)) }
            },
            {
                inspection: Inspections.ManualGrading,
                key: Inspections.ManualGrading.toString(),
                iconProps: { iconName: getInspectionIcon(Inspections.ManualGrading), style: { color: theme.palette.black, fontSize: 14 } },
                text: getLocalizedInspectionName(Inspections.ManualGrading),
                onClick: () => { props.onChangeDispatch(actionCreators.inspections.manualGrades.addManualGrades(ProcessStages.Assessment, ManualGradingModes.Default)) }
            }
        ]
    }

    newInspectionMenuProps.items = newInspectionMenuProps.items.map(function (value) {
        if((props.deviceCheckSolution == DeviceCheckSolutions.PiceaMobile) && (value.key == Inspections.Erase)) {
            value.title = strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.DEVICE_CHECK_SOLUTION_FEATURE_IS_NOT_SUPPORTED;
            value.disabled = true;
            if (value.iconProps && value.iconProps.style) {
                value.iconProps.style.color = 'rgb(174 172 170)';
            }
        }
        if( value.inspection === Inspections.Software) {
            if( !CheckAssesmentModuleAccess( props.channel, value.inspection, value.key)) {
                value.title = strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.DEVICE_CHECK_SOLUTION_FEATURE_IS_NOT_SUPPORTED;
                value.disabled = true;
                if (value.iconProps && value.iconProps.style) {
                    value.iconProps.style.color = 'rgb(174 172 170)';
                }   
            }
        }
        if (value.subMenuProps !== undefined && value.subMenuProps.items) {
            value.subMenuProps.items = value.subMenuProps.items.map((item) => {
                let disableTitle = "";

                if (props.config.modules.find(m => m.type.toString() == value.key && (m.config as any)?.mode.toString() == item.key)) {
                    disableTitle = strings.CONSTRUCTOR.INSPECTIONS.COMMON.MENU_ITEM_IS_ALREADY_SELECTED;
                }

                if (!CheckAssesmentModuleAccess(props.channel, value.inspection, item.key)) {
                    if (disableTitle.length > 0) {
                        disableTitle += '\n';
                    }
        
                    disableTitle += strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.SERVICE_CHANNEL_FEATURE_IS_NOT_SUPPORTED;
                }

               if (disableTitle.length > 0) {
                    item.disabled = true;
                    item.title = disableTitle;
                    if (item.iconProps && item.iconProps.style ){
                        item.iconProps.style.color = 'rgb(174 172 170)';
                    }
                }
                return item;
            });
        }

        let disableTitle = "";

        if (value.subMenuProps && !value.subMenuProps.items.find(i => !i.disabled)) {
            disableTitle = strings.CONSTRUCTOR.INSPECTIONS.COMMON.ALL_SUBMENU_ITEMS_ARE_ALREADY_SELECTED;
        }
        
        if (!value.subMenuProps && props.config.modules.find(m => m.type.toString() === value.inspection)) {
            disableTitle = strings.CONSTRUCTOR.INSPECTIONS.COMMON.MENU_ITEM_IS_ALREADY_SELECTED;
        }

        // We can select only one item from Photos - Free or Collection.
        if (value.key == Inspections.Photographic.toString() && props.config.modules.find(m => m.type.toString() == Inspections.Photographic.toString())) {
            disableTitle = strings.CONSTRUCTOR.INSPECTIONS.COMMON.MENU_ITEM_IS_ALREADY_SELECTED;
        }

        if (((value.inspection === Inspections.Erase || value.inspection === Inspections.DataCollection ) && props.channel != Experience.Retail) || 
            value.inspection === Inspections.ManualGrading && props.channel === Experience.Retail) {
            if (disableTitle.length > 0) {
                disableTitle += '\n';
            }

            disableTitle += strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.SERVICE_CHANNEL_FEATURE_IS_NOT_SUPPORTED;
        }

        if (disableTitle.length > 0) {
            value.disabled = true;
            value.title = disableTitle;
            if (value.iconProps && value.iconProps.style) {
                value.iconProps.style.color = 'rgb(174 172 170)';
            }
        }
        return value;
    });
    newInspectionMenuProps.styles = { subComponentStyles: { menuItem: { color:theme.palette.green, cursor:'help'}}};

    const commands = () => (
        <>
            <CommandBarButton iconProps={{ iconName: "Add" }} menuProps={newInspectionMenuProps} text={strings.BUTTONS.TEXT.ADD} disabled={false} />
        </>
    )

    return (
        <StageBox title={strings.CONSTRUCTOR.STAGES.ASSESSMENT.TITLE} onCommandRender={commands} settingsButtonProps={{ onClick: props.showSettings }} width={480}>
            <Stack tokens={{ childrenGap: 0 }}>
                {props.config.modules?.sort((a, b) => a.index - b.index).map((i, localIndex) => {
                    if (i) {
                        return (
                            <InspectionItem
                                key={`${localIndex}-${i.index}-${i.type}`}
                                stage={ProcessStages.Assessment}
                                onChangeDispatch={props.onChangeDispatch}
                                inspection={i}
                                icon={i.config.ui?.icon ?? getInspectionIcon(i.type)}
                                setInspectionParams={props.setInspectionParams}
                                useGradeCategories={useGradesCategories}
                                gradesCategories={gradesCategories}
                                isExperimental={[Inspections.Erase].includes(i.type)}
                                withoutGrades={[Inspections.DataCollection, Inspections.Erase].includes(i.type)}
                            />
                        )
                    }
                })}
            </Stack>
            {props.config.modules?.length === 0 && (
                <Stack styles={{
                    root: {
                        color: theme.palette.black,
                        boxShadow: theme.effects.elevation16,
                        position: "relative",
                    }
                }}>
                    <MessageBar isMultiline messageBarType={MessageBarType.severeWarning}>
                        <b>{strings.CONSTRUCTOR.INSPECTIONS.COMMON.WARNING_EMPTY_ASSESSMENT}</b>
                    </MessageBar>
                </Stack>
            )}
        </StageBox>
    )
}

const theme = getTheme();
